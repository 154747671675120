<template>
  <div>
    <contractors-add></contractors-add>
  </div>
</template>

<script>
import ContractorsAdd from "@/components/ecologist/classifiers/contractors/contractorsAdd";
export default {
  name: "contractorsAddView",
  components: { ContractorsAdd },
};
</script>

<style scoped></style>
