<template>
  <div>
    <el-form
      :model="addContractorsForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Наименование">
            <el-select
              style="width: 100%"
              @change="getEgr"
              :disabled="typeWriteTitle"
              class="searchPole"
              clearable
              v-model="addContractorsForm.egr"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Полное наименование"
              :remote-method="getEgrNames"
            >
              <el-option
                v-for="item in egrNamesList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-checkbox @change="changeTypeWriteTitle" v-model="typeWriteTitle"
              >Ручной ввод наименования</el-checkbox
            >
            <el-input
              :disabled="!typeWriteTitle"
              v-model="addContractorsForm.nameManual"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Местонахождение (юридический адрес)">
            <el-input
              :disabled="!typeWriteTitle"
              v-model="addContractorsForm.addressManual"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Телефон, факс">
            <el-input
              :disabled="!typeWriteTitle"
              v-model="addContractorsForm.phoneNumberManual"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Номер договора">
            <el-input v-model="addContractorsForm.contractNumber"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Дата подписания договора">
            <el-date-picker
              style="width: 100%"
              type="daterange"
              value-format="yyyy-MM-dd"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания"
              v-model="addContractorsForm.contractDate"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="ФИО ответственного лица, телефон">
            <el-input
              v-model="addContractorsForm.responsiblePersonNamePhone"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-button @click="save" type="primary">Добавить</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "contractorsAdd",
  mixins: [notification],
  data() {
    return {
      typeWriteTitle: false,
      addContractorsForm: {
        nameManual: null,
        addressManual: null,
        phoneNumberManual: null,
        contractNumber: null,
        contractDate: null,
        contractDateFrom: null,
        contractDateTo: null,
        responsiblePersonNamePhone: null,
        egr: {
          address: null,
          contacts: null,
          id: null,
          name: null,
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      egrNamesList: "GETEGRNAMESLIST",
      egrList: "GETEGRLIST",
    }),
  },

  methods: {
    async getEgr() {
      await this.$store.dispatch(
        "getEgrObject",
        this.addContractorsForm.egr.id
      );
      this.addContractorsForm.phoneNumberManual =
        this.egrList.content[0].phoneNumber;
      this.addContractorsForm.addressManual = this.egrList.content[0].address;
    },

    changeTypeWriteTitle() {
      if (this.typeWriteTitle) {
        this.addContractorsForm.egrId = null;
      } else {
        this.addContractorsForm.nameManual;
      }
    },

    save() {
      if (!this.typeWriteTitle) {
        this.addContractorsForm.egrId = this.addContractorsForm.egr.id;
        this.addContractorsForm.nameManual = this.addContractorsForm.egr.name;
      }
      if (this.addContractorsForm.contractDate !== null) {
        this.addContractorsForm.contractDateFrom =
          this.addContractorsForm.contractDate[0];
        this.addContractorsForm.contractDateTo =
          this.addContractorsForm.contractDate[1];
      }

      this.$store
        .dispatch("createContractor", this.addContractorsForm)
        .then((response) => {
          console.log(response);
          this.notification("Успешно", "Контрагент добавлен", "success");
          this.$store.dispatch("setShowModal", null);
          this.$store.dispatch("getAllContractors", { page: 0, size: 20 });
        });
    },

    getEgrNames(query) {
      this.$store.dispatch("getEgrNames", {
        query: query,
        size: 100,
        page: 0,
      });
    },
  },

  mounted() {
    this.getEgrNames("");
  },
};
</script>

<style scoped></style>
